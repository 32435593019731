/**
 * 请求接口地址常量
 */
const shipperApi = {
    // 平台发货地列表
    shipperList: '/setting/shipper/list',
    //删除平台发货地址
    shipperDel: '/setting/shipper/delete',
    //新建平台发货地址
    shipperAdd: '/setting/shipper/add',
    //编辑平台发货地址
    shipperEdit: '/setting/shipper/edit',
}


export default shipperApi