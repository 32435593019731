<template>
  <el-card class="body" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form
        :model="addForm"
        label-width="150px"
        :label-position="labelPosition"
        :rules="rules"
        ref="inserform"
      >
        <el-form-item label="联系人：" prop="shipper">
          <el-input v-model.trim="addForm.shipper"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model.trim="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="详情地址：" prop="adddress">
          <el-input type="textarea" v-model="addForm.adddress"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/setting/shipper/list">
          <el-button>取 消</el-button>
        </router-link>
        <el-button
          type="primary"
          style="margin-left: 50px"
          @click="shipperAdd('inserform')"
          >确 定</el-button
        >
      </div>
    </div>
  </el-card>
</template>
<script>
import { shipperAdd } from "@/api/shipper";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      labelPosition: "right",
      addForm: {},
      rules: {
        shipper: [
          { required: true, message: "请填写收货人姓名", trigger: "blur" },
        ],
        mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
        adddress: [
          { required: true, message: "请填写详情地址", trigger: "blur" },
        ],
      },
    };
  },
  created() {},

  methods: {
    //新建地址列表
    shipperAdd(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          shipperAdd(this.addForm).then(() => {
            this.$router.push("/setting/shipper/list");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
</style>