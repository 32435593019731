import request from "@/utils/request";
import shipperApi from '@/const/apiUrl/shipper';
import method from "@/const/request/requestMethod";

// 平台发货地列表
export function shipperList(params) {
    return request({
        url: shipperApi.shipperList,
        method: method.GET,
        data: params
    })
}
//删除平台发货地址
export function shipperDel(params) {
    return request({
        url: shipperApi.shipperDel,
        method: method.GET,
        data: params
    })
}
//新建平台发货地址
export function shipperAdd(params) {
    return request({
        url: shipperApi.shipperAdd,
        method: method.POST,
        data: params
    })
}
//编辑平台发货地址
export function shipperEdit(params) {
    return request({
        url: shipperApi.shipperEdit,
        method: method.POST,
        data: params
    })
}
